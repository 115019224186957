import React from "react"
import { graphql } from "gatsby"

import Layout from "components/Layout"
import HeroSubpage from "components/HeroSubpage"

import { Contact } from "page_components/home"
import { Team, ServicesRedirect } from "page_components/team"

const TeamPage = ({ data, location }) => {
  const pageData = data.allWpPage.nodes[0]
  const teamData = data.allWpTeam.nodes

  return (
    <Layout
      seo={{
        title: pageData?.title,
      }}
      location={location}
    >
      <HeroSubpage title={pageData?.title} />
      <Team pageData={pageData} teamData={teamData} />
      <ServicesRedirect />
      <Contact isSubpage />
    </Layout>
  )
}

export const query = graphql`
  query ($language: String) {
    allWpPage(filter: { id: { eq: "cG9zdDoyMjg=" } }) {
      nodes {
        id
        title
        acfTeam {
          heroHeader
        }
      }
    }

    allWpTeam(
      filter: { language: { slug: { eq: $language } } }
      sort: { fields: date }
    ) {
      nodes {
        title
        acfTeam {
          image {
            sourceUrl
          }
          description
          linkedin
          role
        }
        id
      }
    }
  }
`

export default TeamPage
