import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

import Button from "components/Button"

const ServicesRedirect = () => {
  const { t } = useLocale()

  return (
    <section className="services-redirect">
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8">
            <h4>{t("Poznaj nasze")}</h4>
            <h2>{t("Usługi")}</h2>
          </div>
          <div className="col-md-4">
            <div className="services-redirect__cta">
              <Button
                className="button--bordered button--bordered-blue"
                to={t("/uslugi/")}
              >
                {t("Więcej")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ServicesRedirect
