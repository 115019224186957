import "./styles.scss"

import React from "react"

import { TeamLayout } from "page_components/home/Team/components"

const Team = ({ pageData, teamData }) => {
  return (
    <section className="team">
      <div className="team__header">
        <div className="container-fluid">
          <div className="row">
            <div className="col-xl-5">
              <h3>{pageData.acfTeam.heroHeader}</h3>
            </div>
            <div className="col-xl-7">
              <div className="team__header-image">
                <img
                  src={
                    require("assets/images/team/mcg-header-image.svg").default
                  }
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <TeamLayout teamData={teamData}/>
      </div>
    </section>
  )
}

export default Team
